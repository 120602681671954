import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const ProfileConnect = () => {

  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState(null);

  const [info_message, setInfo] = useState('');
  const [success, setSuccess] = useState(false);

  const [user, setUser] = useState(false);
  const user_id = searchParams.get("user_id");

  let api_base = 'https://api.diningsocialnj.com' // 'http://localhost:3000' 

  useEffect(() => {
    // Define an async function inside the useEffect
    const fetchUser = async () => {
      if (!user_id) return; // Avoid making a request if user_id is null or undefined
      try {
        const response = await fetch(`${api_base}/api/get_user`, {
          method: 'POST', // Specify the HTTP method
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id }), // Pass user_id in the body
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('user', data);

        setUser(data); // Update the user state with the response data
      } catch (error) {
        console.error('Failed to fetch user:', error);
      }
    };

    fetchUser();
  }, [user_id]);

  const updateEmail = (email_text) => {
    
    setEmail(email_text);
  }

  const inviteEmail = async () => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(String(email).toLowerCase())) {
      try {
        const response = await fetch(`${api_base}/api/invite`, {
          method: 'POST', // Specify the HTTP method
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, user_id }), // Pass user_id in the body
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('user', data);

        setInfo('Invite has been sent!');
        setSuccess(true)
      } catch (error) {
        console.error('Failed to fetch user:', error);
      }
    } else {
      setInfo("Email is not valid :-(")
    }
  }

  return <div style={{backgroundColor: '#f7f8fc', maxWidth: 500, margin: 'auto', padding: 50, textAlign: 'center'}}>
    <h1 style={{fontSize: 20}}>You're Connecting with</h1>
    <p style={{fontSize: 40}}>{user && user.user ? user.user.profile.name : null}</p>

    {success ? 
      <div style={{fontSize: 30, color: 'green', textShadow: '1px 1px 1px #17c517'}}>Invite sent!<br/> <span style={{fontSize: 20}}>Keep chatting and see ya soon!</span></div>
    :
    <div>
      <div style={{marginTop: 20, marginBottom: 20}}>
        <input value={email || ''} // Ensures the input is controlled
          onChange={(e) => updateEmail(e.target.value)} type="email" style={{width: 200, borderWidth: 1, borderRadius: 3, padding: 10}} placeholder={"Your email"} />
        <button onClick={inviteEmail} style={{marginLeft: 10, backgroundColor: '#fdcd37', padding: 20, paddingTop: 10, paddingBottom: 10, borderRadius: 5}}>Send</button>
      </div>
      {info_message ? <div style={{color: success ? 'green' : 'red', marginBottom: 20, textAlign: 'left'}}>{info_message}</div> : null}
      <div style={{fontSize: 20}}>Keep chatting! We'll take care of the rest by sending info to your email. We'll send you instructions to download the app and connect with {user && user.user ? user.user.profile.name : null} directly.</div>
    </div>}

  </div>
};

export default ProfileConnect;
