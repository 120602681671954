import React from "react";

const Contact = () => (
  <div>
    <h1>Contact Us</h1>
    <p>Feel free to reach out with any questions.</p>
  </div>
);

export default Contact;
