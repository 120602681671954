import React, { useState, useEffect } from "react";

import config from '../config/index.json';
import LazyShow from '../components/LazyShow';
import Divider from '../components/Divider';

const Home = () => {

  let { product } = config;
  let { items } = product;

  let firstItem = items[0];
  let secondItem = items[1];

    return <div className={`bg-background grid gap-y-16 overflow-hidden`}>

      <div className={`relative bg-background`}>
        <div className="max-w-7xl mx-auto">
          <div
            className={`relative z-10 pb-8 bg-background sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32`}
          >
  <div>
    <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
      <div className="sm:text-center lg:text-left">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block xl:inline">{config.mainHero.title}</span>{' '}
          <span className={`block text-primary xl:inline`}>
            {config.mainHero.subtitle}
          </span>
        </h1>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
        Socialize with members in New Jersey on your own time, any day of the week, in small bunches.
        <div style={{marginBottom: 100}} className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
          <div className="rounded-md shadow">
            <a
              href={config.mainHero.primaryAction.href}
              className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-background bg-primary hover:bg-border hover:text-primary md:py-4 md:text-lg md:px-10`}
            >
              {config.mainHero.primaryAction.text}
            </a>
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-3">
            <a
              href={config.mainHero.secondaryAction.href}
              className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md border-primary text-secondary bg-background hover:bg-border hover:text-primary md:py-4 md:text-lg md:px-10`}
            >
              {config.mainHero.secondaryAction.text}
            </a>
          </div>
        </div>
          <ul style={{marginLeft: 30}}>
            <li style={{listStyle: 'inside', marginTop: 30}}>
              <b>Make new friends</b> by connecting with our members based on shared age, gender, or interests. Meet up at your preferred public places in New Jersey in small groups of up to four individuals (or a Bunch 😀) at a time.
            </li>
            <li style={{listStyle: 'inside', marginTop: 30}}>
              <b>Add your friends</b> on Bunch and invite them to exclusive, invite-only gatherings. Bunch makes planning and communicating with your entire group simple—no more juggling endless text message threads.
            </li>
            <li style={{listStyle: 'inside', marginTop: 30}}>
              <b>Share your Bunch account at networking events</b> to foster real-life connections. Instead of letting new acquaintances get lost in the social media abyss, connect on Bunch to easily plan a coffee, lunch, or dinner later on.
            </li>
            <li style={{listStyle: 'inside', marginTop: 30}}>
              And the best thing - <b>IT'S ALL FREE</b>
            </li>
          </ul>
        </p>
        <div style={{marginTop: 100}} className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
          <div className="rounded-md shadow">
            <a
              href={config.mainHero.primaryAction.href}
              className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-background bg-primary hover:bg-border hover:text-primary md:py-4 md:text-lg md:px-10`}
            >
              {config.mainHero.primaryAction.text}
            </a>
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-3">
            <a
              href={config.mainHero.secondaryAction.href}
              className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md border-primary text-secondary bg-background hover:bg-border hover:text-primary md:py-4 md:text-lg md:px-10`}
            >
              {config.mainHero.secondaryAction.text}
            </a>
          </div>
        </div>
      </div>
    </main>
  </div>
  </div>

  <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img
        className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
        src={config.mainHero.img}
        alt="happy team image"
      />
    </div>



        </div>


        </div>

        {/* <LazyShow>
        <section className={`bg-background py-8`} id="product">
      <div className={`container max-w-5xl mx-auto m-8`}>
        <h1
          className={`w-full my-2 text-5xl font-bold leading-tight text-center text-primary`}
        >
          {product.title.split(' ').map((word, index) => (
            <span
              key={index}
              className={index % 2 ? 'text-primary' : 'text-border'}
            >
              {word}{' '}
            </span>
          ))}
        </h1>
        <Divider />
        <div className={`flex flex-wrap`}>
          <div className={`w-5/6 sm:w-1/2 p-6 mt-20`}>
            <h3
              className={`text-3xl text-gray-800 font-bold leading-none mb-3`}
            >
              {firstItem?.title}
            </h3>
            <p className={`text-gray-600`}>{firstItem?.description}</p>
          </div>
          <div className={`w-full sm:w-1/2 p-6`}>
            <img
              className="h-6/6"
              src={firstItem?.img}
              alt={firstItem?.title}
            />
          </div>
        </div>
        <div className={`flex flex-wrap flex-col-reverse sm:flex-row`}>
          <div className={`w-full sm:w-1/2 p-6`}>
            <img
              className="h-6/6"
              src={secondItem?.img}
              alt={secondItem?.title}
            />
          </div>
          <div className={`w-full sm:w-1/2 p-6 mt-20`}>
            <div className={`align-middle`}>
              <h3
                className={`text-3xl text-gray-800 font-bold leading-none mb-3`}
              >
                {secondItem?.title}
              </h3>
              <p className={`text-gray-600 mb-8`}>{secondItem?.description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
        </LazyShow> */}
        </div>
};

export default Home;
