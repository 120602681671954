import React from "react";
import { style } from './style';

const Terms = () => (
  <div style={style.explainer_section}>
      <div style={style.restaurants_wrapper}>
        <div style={style.restaurant_wrapper}>
          <div style={style.restaurant_title}>Terms & Conditions</div>
          <div style={style.legal}>
            
<div style={style.legal_div}></div>
<div style={style.legal_div}>These Terms and Conditions ("Agreement") govern your use of the subscription service ("Service") provided by Focal Point Technologies ("Company") and apply to all users ("User" or "You") who subscribe to the service. By registering to the Service, you acknowledge that you have read, understood, and agreed to be bound by these Terms and Conditions. If you do not agree with any part of this Agreement, please refrain from using the Company's Service. Please read this Agreement carefully before registering.</div>
<div></div>
<div style={style.legal_title}>1. AGREEMENT TO TERMS</div>
<div></div>
<div style={style.legal_div}>These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Focal Point Technologies ("Company," “we," “us," or “our”), concerning your access to and use of the Bunch app as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the "App"). We are registered in New Jersey, United States. You agree that by accessing the App, you have read, understood, and agreed to be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.</div>
<div style={style.legal_div}></div>
<div style={style.legal_div}>Supplemental terms and conditions or documents that may be posted on the App from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use from time to time. We will alert you about any changes by updating the “Last updated” date of these Terms of Use, and you waive any right to receive specific notice of each such change. Please ensure that you check the applicable Terms every time you use our App so that you understand which Terms apply. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Use by your continued use of the App after the date such revised Terms of Use are posted.</div>
<div style={style.legal_div}></div>
<div style={style.legal_div}>The information provided on the App is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the App from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</div>
<div style={style.legal_div}></div>
<div style={style.legal_div}>The App is not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions would be subjected to such laws, you may not use this App. You may not use the App in a way that would violate the Gramm-Leach-Bliley Act (GLBA).</div>
<div style={style.legal_div}></div>
<div style={style.legal_div}>The App is intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the App.</div>
<div></div>
<div></div>
<div style={style.legal_title}>2. INTELLECTUAL PROPERTY RIGHTS</div>
<div></div>
<div style={style.legal_div}>Unless otherwise indicated, the App is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, div, photographs, and graphics on the App (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, international copyright laws, and international conventions. The Content and the Marks are provided on the App “AS IS” for your information and personal use only. Except as expressly provided in these Terms of Use, no part of the App and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.</div>
<div style={style.legal_div}></div>
<div style={style.legal_div}>Provided that you are eligible to use the App, you are granted a limited license to access and use the App and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the App, the Content and the Marks.</div>
<div></div>
<div></div>
<div style={style.legal_title}>3. USER REPRESENTATIONS</div>
<div></div>
<div style={style.legal_div}>By using the App, you represent and warrant that: (1) all registration information you submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such registration information as necessary; (3) you have the legal capacity and you agree to comply with these Terms of Use; (4) you are not a minor in the jurisdiction in which you reside; (5) you will not access the App through automated or non-human means, whether through a bot, script, or otherwise; (6) you will not use the App for any illegal or unauthorized purpose; and (7) your use of the App will not violate any applicable law or regulation.</div>
<div style={style.legal_div}></div>
<div style={style.legal_div}>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the App (or any portion thereof).</div>
<div></div>
<div></div>
<div style={style.legal_title}>4. USER REGISTRATION</div>
<div></div>
<div style={style.legal_div}>You may be required to register with the App. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change any information you provide if we determine, in our sole discretion, that such information is inappropriate, obscene, or otherwise objectionable.</div>
<div></div>
<div></div>
<div style={style.legal_title}>5. PROHIBITED ACTIVITIES</div>
<div></div>
<div style={style.legal_div}>You may not access or use the App for any purpose other than that for which we make the App available. The App may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.</div>
<div></div>
<div style={style.legal_div}>As a user of the App, you agree not to:</div>
<div style={style.legal_div_list}>Systematically retrieve data or other content from the App to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</div>
<div style={style.legal_div_list}>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</div>
<div style={style.legal_div_list}>Circumvent, disable, or otherwise interfere with security-related features of the App, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the App and/or the Content contained therein.</div>
<div style={style.legal_div_list}>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the App.</div>
<div style={style.legal_div_list}>Use any information obtained from the App in order to harass, abuse, or harm another person.</div>
<div style={style.legal_div_list}>Make improper use of our support services or submit false reports of abuse or misconduct.</div>
<div style={style.legal_div_list}>Use the App in a manner inconsistent with any applicable laws or regulations.</div>
<div style={style.legal_div_list}>Engage in unauthorized framing of or linking to the App.</div>
<div style={style.legal_div_list}>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive div), that interferes with any party’s uninterrupted use and enjoyment of the App or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the App.</div>
<div style={style.legal_div_list}>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</div>
<div style={style.legal_div_list}>Delete the copyright or other proprietary rights notice from any Content.</div>
<div style={style.legal_div_list}>Attempt to impersonate another user or person or use the username of another user.</div>
<div style={style.legal_div_list}>Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).</div>
<div style={style.legal_div_list}>Interfere with, disrupt, or create an undue burden on the App or the networks or services connected to the App.</div>
<div style={style.legal_div_list}>Harass, annoy, intimidate, or threaten any of our employees, members or agents engaged in providing any portion of the App to you.</div>
<div style={style.legal_div_list}>Attempt to bypass any measures of the App designed to prevent or restrict access to the App, or any portion of the App.</div>
<div style={style.legal_div_list}>Copy or adapt the App’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</div>
<div style={style.legal_div_list}>Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the App.</div>
<div style={style.legal_div_list}>Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the App, or using or launching any unauthorized script or other software.</div>
<div style={style.legal_div_list}>Use a buying agent or purchasing agent to make purchases on the App.</div>
<div style={style.legal_div_list}>Make any unauthorized use of the App, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.</div>
<div style={style.legal_div_list}>Use the App as part of any effort to compete with us or otherwise use the App and/or the Content for any revenue-generating endeavor or commercial enterprise.</div>
<div></div>
<div></div>
<div style={style.legal_title}>6. USER GENERATED CONTRIBUTIONS</div>
<div></div>
<div style={style.legal_div}>The App does not offer users to submit or post content. We may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the App, including but not limited to div, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Contributions"). Contributions may be divable by other users of the App and through third-party websites. As such, any Contributions you transmit may be treated in accordance with the App Privacy Policy. When you create or make available any Contributions, you thereby represent and warrant that:</div>
<div style={style.legal_div_list}>The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.</div>
<div style={style.legal_div_list}>You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the App, and other users of the App to use your Contributions in any manner contemplated by the App and these Terms of Use.</div>
<div style={style.legal_div_list}>You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the App and these Terms of Use.</div>
<div style={style.legal_div_list}>Your Contributions are not false, inaccurate, or misleading.</div>
<div style={style.legal_div_list}>Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.</div>
<div style={style.legal_div_list}>Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).</div>
<div style={style.legal_div_list}>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</div>
<div style={style.legal_div_list}>Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people.</div>
<div style={style.legal_div_list}>Your Contributions do not violate any applicable law, regulation, or rule.</div>
<div style={style.legal_div_list}>Your Contributions do not violate the privacy or publicity rights of any third party.</div>
<div style={style.legal_div_list}>Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.</div>
<div style={style.legal_div_list}>Your Contributions do not include any offensive comments that are connected to race, origin, gender, sexual preference, or physical handicap.</div>
<div style={style.legal_div_list}>Your Contributions do not otherwise violate, or link to material that violates, any provision of these Terms of Use, or any applicable law or regulation.</div>
<div style={style.legal_div}>Any use of the App in violation of the foregoing violates these Terms of Use and may result in, among other things, termination or suspension of your rights to use the App.</div>
<div></div>
<div></div>
<div style={style.legal_title}>7. CONTRIBUTION LICENSE</div>
<div></div>
<div style={style.legal_div}>You and the App agree that we may access, store, process, and use any information and personal data that you provide following the terms of the Privacy Policy available at https://www.diningsocialnj.com/privacy and your choices (including settings).</div>
<div style={style.legal_div}></div>
<div style={style.legal_div}>By submitting suggestions or other feedback regarding the App, you agree that we can use and share such feedback for any purpose without compensation to you.</div>
<div style={style.legal_div}></div>
<div style={style.legal_div}>We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area on the App. You are solely responsible for your Contributions to the App and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.</div>
<div></div>
<div></div>
<div style={style.legal_title}>8. SUBMISSIONS</div>
<div></div>
<div style={style.legal_div}>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the App ("Submissions") provided by you to us are non-confidential and shall become our sole property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you. You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such Submissions are original with you or that you have the right to submit such Submissions. You agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions.</div>
<div></div>
<div></div>
<div style={style.legal_title}>9. SITE MANAGEMENT</div>
<div></div>
<div style={style.legal_div}>We reserve the right, but not the obligation, to: (1) monitor the App for violations of these Terms of Use; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the App or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the App in a manner designed to protect our rights and property and to facilitate the proper functioning of the App.</div>
<div></div>
<div></div>
<div style={style.legal_title}>10. PRIVACY POLICY</div>
<div></div>
<div style={style.legal_div}>We care about data privacy and security. Please rediv our Privacy Policy: https://www.diningsocialnj.com/privacy. By using the App, you agree to be bound by our Privacy Policy, which is incorporated into these Terms of Use. Please be advised the App is hosted in the United States. If you access the App from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the United States, then through your continued use of the App, you are transferring your data to the United States, and you agree to have your data transferred to and processed in the United States.</div>
<div></div>
<div></div>
<div style={style.legal_title}>11. TERM AND TERMINATION</div>
<div></div>
<div style={style.legal_div}>These Terms of Use shall remain in full force and effect while you use the App. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.</div>
<div style={style.legal_div}></div>
<div style={style.legal_div}>Either of us may terminate any Product Subscription under these Terms immediately. We may terminate any Product Subscription under these Terms upon our reasonable determination that your Use of the Product or Documentation or Services (i) violates any applicable law or regulation or (ii) poses a threat to the secure or reliable provision of Services to other customers, or to the Cloud Infrastructure, or to the data contained therein.</div>
<div style={style.legal_div}></div>
<div style={style.legal_div}>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</div>
<div style={style.legal_div}></div>
<div style={style.legal_div}>Any termination of any Product Subscription pursuant to these Terms shall be without prejudice to any other rights or remedies a party may be entitled to hereunder or at law and shall not affect any accrued rights or liabilities of either party nor the coming into or continuance in force of any provision hereof which is expressly or by implication intended to come into or continue in force on or after such termination. Upon termination of any Product Subscription pursuant to these Terms, you will immediately uninstall or destroy (or at our sole option) all copies of the Product and Documentation in its possession or control.</div>
<div></div>
<div></div>
<div style={style.legal_title}>12. MODIFICATIONS AND INTERRUPTIONS</div>
<div></div>
<div style={style.legal_div}>We reserve the right to change, modify, or remove the contents of the App at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our App. We also reserve the right to modify or discontinue all or part of the App without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the App.</div>
<div></div>
<div style={style.legal_div}>We cannot guarantee the App will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the App, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the App at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the App during any downtime or discontinuance of the App. Nothing in these Terms of Use will be construed to obligate us to maintain and support the App or to supply any corrections, updates, or releases in connection therewith.</div>
<div></div>
<div>

<div style={style.legal_title}>13. Host Dinner Offer</div>

<div style={style.legal_div}>
  
  As a special offer for our dinner event, the designated host will be provided with one complimentary entreé and one complimentary drink. This offer is subject to the following terms and conditions:</div>
<div style={style.legal_div}></div>
<div style={style.legal_div_list}>Restaurant Responsibility: We strive to ensure that the designated host receives the complimentary entreé and drink as part of our agreement with the restaurant. However, the fulfillment of this offer is the sole responsibility of the restaurant.</div>

<div style={style.legal_div_list}>Limited Liability: In the event that the restaurant fails to provide the designated host with the agreed-upon complimentary entreé and drink, we, as the event organizer, are not liable for any costs, damages, or claims arising from this non-fulfillment.</div>

<div style={style.legal_div_list}>Communication and Resolution: We will make reasonable efforts to communicate the host dinner offer to the restaurant and coordinate with them to ensure its proper execution. In case of any issues or discrepancies, we will work with the restaurant and the host to resolve the matter in a fair and satisfactory manner. However, we cannot guarantee the availability or quality of the complimentary dinner provided by the restaurant.</div>

<div style={style.legal_div_list}>Substitution or Alternative Arrangements: If the restaurant is unable to fulfill the host dinner offer due to unforeseen circumstances, we reserve the right to provide a suitable substitution or alternative arrangement at our discretion. Such substitution or alternative arrangements will be of similar value and will be communicated to the host in a timely manner.</div>

<div style={style.legal_div_list}>Host Acknowledgment: By accepting the host dinner offer, the designated host acknowledges and agrees to the terms and conditions outlined in this section, understanding that the fulfillment of the complimentary dinner is the responsibility of the restaurant, and that we, as the event organizer, are not liable for any non-fulfillment by the restaurant.</div>
</div>



<div style={style.legal_title}>14. USE OF PHOTOS</div>
<div style={style.legal_div}>

By participating in our events, you grant us permission to use any photographs or videos taken of you during the events for promotional and marketing purposes, including but not limited to displaying them on our website. This allows us to showcase the experience and atmosphere of our events to our audience.
</div>
<div style={style.legal_div}>You understand and agree that:</div>

<div style={style.legal_div_list}>
Ownership and Rights: We retain full ownership and copyright of the photographs/videos taken by our authorized representatives during the events. You do not acquire any ownership or rights to these photographs/videos.
</div>
<div style={style.legal_div_list}>
Consent for Use: You provide us with your consent to use the photographs/videos featuring you for promotional and marketing purposes. This includes, but is not limited to, displaying the photographs/videos on our website, social media channels, printed materials, and any other marketing collateral.
</div>
<div style={style.legal_div_list}>
Release of Liability: You release us, our representatives, employees, and any third parties acting under our permission or authority from any claims, demands, or liabilities arising out of or in connection with the use of the photographs/videos, including any claims for defamation, invasion of privacy, or infringement of moral rights.
</div>
<div style={style.legal_div_list}>
Exclusion of Compensation: You acknowledge and agree that you will not receive any compensation, financial or otherwise, for the use of the photographs/videos featuring you.
</div>
<div style={style.legal_div_list}>
Confidentiality: We will make reasonable efforts to ensure that the photographs/videos used for promotional purposes do not reveal sensitive personal information or cause harm to your reputation. However, we cannot guarantee the absolute confidentiality or security of these photographs/videos.
</div>
<div style={style.legal_div}>
If you do not wish to grant us permission to use photographs/videos featuring you, please inform our event staff before participating in any events.
</div>

<div style={style.legal_title}>15.  PAYMENT TERMS</div>
<div></div>
<div style={style.legal_title}>Pricing and Payment:</div>
<div style={style.legal_div}>By selecting any pricing plan and proceeding with the purchase, you agree to be bound by the following terms and conditions:</div>
<div style={style.legal_div_list}>Prices are in USD and subject to applicable taxes, fees, and charges.</div>
<div style={style.legal_div_list}>Payment in full is required at the time of purchase.</div>
<div style={style.legal_div_list}>All payments are non-refundable.</div>
<div></div>
<div style={style.legal_title}>Subscription and Renewal:</div>
<div style={style.legal_div_list}>Subscriptions will automatically renew at the end of each billing cycle unless canceled.</div>
<div style={style.legal_div_list}>Monthly subscriptions can be canceled at any time, effective at the end of the current billing month.</div>
<div style={style.legal_div_list}>Yearly subscriptions can be canceled at any time, effective at the end of the current subscription year.</div>
<div style={style.legal_div_list}>No refunds or prorated reimbursements will be provided for partial periods.</div>

<div style={style.legal_title}>RSVP Cancelations & Refunds:</div>
<div style={style.legal_div_list}>Once you complete your purchase, there will be no refunds given for any reason other than the event being canceled.</div>
<div style={style.legal_div_list}>The attendance fee aims partially to prevent no-shows. If you have purchased the rsvp fee, no refunds will be provided for that purchase.</div>
<div></div>

<div style={style.legal_title}>Pricing Modifications:</div>
<div style={style.legal_div}>The Company reserves the right to change any subscription fees and give you the right to reject or accept the new fees within the next billing cycle.</div>

<div style={style.legal_title}>Invoicing and Payment:</div>
<div style={style.legal_div}>The Company's right to payment for any Product or Service purchased by you shall accrue on the date the Product or Service is Delivered to you. Except as otherwise expressly provided in these Terms, all payments accrued or made under these Terms are non-cancelable and nonrefundable. All stated prices are exclusive of any taxes, fees, and duties or other amounts, however designated, and including without limitation value added and withholding taxes that are levied or based upon such charges, or upon these Terms. Any taxes related to the Product, Documentation or Services purchased or licensed pursuant to these Terms including, but not limited to, withholding taxes, will be paid by you, or you will present an exemption certificate acceptable to the taxing authorities. You will not be liable for taxes based on our net income. All payments will be made in United States dollars.</div>
<div style={style.legal_title}>Your Failure to Pay or Failure to Pay on Time:</div>
<div style={style.legal_div}>If you have any outstanding balance due on your account, then we have the right to withhold Delivering any Product and Services to you until you have paid your account balance in full. If your payment is late, we may charge interest on your outstanding late balance at the rate of one and one-half percent (1.5%) per month or the maximum rate permitted by law, calculated from the date such amount was due until the date that payment is received by us. You agree to reimburse us for all reasonable costs and expenses incurred (including reasonable attorneys' fees) in collecting any overdue amounts.</div>

<div></div>
<div></div>
<div style={style.legal_title}>16.  GOVERNING LAW</div>
<div></div>
<div style={style.legal_div}>These Terms of Use and your use of the App are governed by and construed in accordance with the laws of the State of New Jersey applicable to agreements made and to be entirely performed within the State of New Jersey, without regard to its conflict of law principles.</div>
<div></div>
<div></div>
<div style={style.legal_title}>17. DISPUTE RESOLUTION</div>
<div></div>
<div style={style.legal_div}>Any legal action of whatever nature brought by either you or us (collectively, the “Parties” and individually, a “Party”) shall be commenced or prosecuted in the state and federal courts located in Hudson, New Jersey, and the Parties hereby consent to, and waive all defenses of lack of personal jurisdiction and forum non conveniens with respect to venue and jurisdiction in such state and federal courts. Application of the United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act (UCITA) are excluded from these Terms of Use. In no event shall any claim, action, or proceeding brought by either Party related in any way to the App be commenced more than one (1) years after the cause of action arose.</div>
<div></div>
<div></div>
<div style={style.legal_title}>18. CORRECTIONS</div>
<div></div>
<div style={style.legal_div}>There may be information on the App that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the App at any time, without prior notice.</div>
<div></div>
<div></div>
<div style={style.legal_title}>19. DISCLAIMER</div>
<div></div>
<div style={style.legal_div}>THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</div>
<div></div>
<div></div>
<div style={style.legal_title}>20. LIMITATIONS OF LIABILITY</div>
<div></div>
<div style={style.legal_div}>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 
THE COMPANY IS ALSO NOT RESPONSIBLE FOR THE BEHAVIOR OR ACTIONS OF DINNER PARTICIPANTS, HOSTS OR ANY PARTIES PRESENT, NOR ANY HAPPENSTANCE DURING THE DINNERS OR OTHERWISE. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE one (1) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING OR $1,000.00 USD. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</div>
<div></div>
<div></div>
<div style={style.legal_title}>21. INDEMNIFICATION</div>
<div></div>
<div style={style.legal_div}>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) use of the App; (2) breach of these Terms of Use; (3) any breach of your representations and warranties set forth in these Terms of Use; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the App with whom you connected via the App. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.</div>
<div></div>
<div></div>
<div style={style.legal_title}>22. USER DATA</div>
<div></div>
<div style={style.legal_div}>We will maintain certain data that you transmit to the App for the purpose of managing the performance of the App, as well as data relating to your use of the App. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the App. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.</div>
<div></div>
<div></div>
<div style={style.legal_title}>23. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</div>
<div></div>
<div style={style.legal_div}>Visiting the App, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the App, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.</div>
<div></div>
<div></div>
<div style={style.legal_title}>24. CALIFORNIA USERS AND RESIDENTS</div>
<div></div>
<div style={style.legal_div}>If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.</div>
<div></div>
<div></div>
<div style={style.legal_title}>25. MISCELLANEOUS</div>
<div></div>
<div style={style.legal_div}>These Terms of Use and any policies or operating rules posted by us on the App or in respect to the App constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or provision. These Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms of Use or use of the App. You agree that these Terms of Use will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.</div>
<div style={style.legal_div}></div>

<div style={style.legal_div}>By using our service you certify and agree to the following </div>
<div style={style.legal_div_list}>1. you understand that with any such service there is the possibility of hacking or data corruption due to unknown bugs, </div>
<div style={style.legal_div_list}>2. you accept the risk and that you hold harmless Focal Point Technologies from any and all liability by using this platform, </div>
<div style={style.legal_div_list}>3. you accept that our service will not be liable for any data loss or corruption in the event of hacking or due to unknown bugs with the service. Each Party agrees to indemnify and hold harmless, to the fullest extent allowed by law, the other Party and its principals, officers, and employees from and against all claims, demands, suits, actions, payments, liabilities, judgments and expenses (including courtordered attorneys' fees), arising out of or resulting from the acts or omissions of their principals, officers, or employees in the performance of this Agreement. Liability includes any claims, damages, losses, and expenses arising out of or resulting from performance of this Agreement that results in any claim for damage whatsoever including any bodily injury, civil rights liability, sickness, disease, or damage to or destruction of tangible property, including the loss of use resulting therefrom. 

<div style={style.legal_div}>Further, each Party shall maintain a policy or policies of insurance (or a selfinsurance program), sufficient in coverage and amount to pay any judgments or related expenses from or in conjunction with any such claims. Nothing in this Agreement shall require either Party to indemnify or hold harmless the other Party from liability for the negligent or wrongful acts or omissions of said other Party or its principals, officers, or employees.</div>

<div style={style.legal_div}></div>
<div style={style.legal_div}></div>
<div style={style.legal_div}>
  Focal Point Technologies
</div>
<div style={style.legal_div}>New Jersey, US</div>
<div style={style.legal_div}>team@diningsocialnj.com</div>
</div>

          </div>
        </div>
        </div>
      </div>
);

export default Terms;
