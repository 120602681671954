import React from "react";

const Footer = () => {

  let year = (new Date()).getFullYear();

  return <footer style={{padding: 40}}>
    <p>&copy; {year} Focal Point Technologies</p>
  </footer>
};

export default Footer;
