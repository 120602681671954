import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => (
  <nav>
   <a href="/" style={{display: 'flex', flexDirection: 'row', width: 200, margin: 20,  textDecorationLine: 'none'}}>
    <img style={{width: 50, height: 50}} src={'https://diningsocial-assets.s3.amazonaws.com/icon.png'} />
    <div style={{fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif', fontSize: 36, alignContent: 'center', marginLeft: 10, color: 'black', textDecorationLine: 'none'}}>Bunch</div>
   </a>
    {/* <ul>
      <li><Link to="/about">About</Link></li>
      <li><Link to="/contact">Contact</Link></li>
    </ul> */}
  </nav>
);

export default Navbar;
